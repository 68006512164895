@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

html,
body {
	overflow-x: hidden;
}

.modal-backdrop,
#global-spinner {
	background-color: rgba($primary, .5);
	backdrop-filter: blur(2px);
}

// Header

.header-logo {

	svg {
		height: 36px;
	}

	.fill-light {
		fill: $gray-200;
	}

	.fill-blue {
		fill: rgba($gray-200, .5);
		transition: fill .15s ease-in-out;
	}

	.fill-red {
		fill: rgba($gray-200, .25);
		transition: fill .3s ease-in-out;
	}

	&:hover {

		.fill-blue {
			fill: $blue;
		}

		.fill-red {
			fill: $red;
		}
	}
}

.header-menu-wrapper {
	margin-top: -.5rem;
	margin-bottom: -.5rem;
}

.btn-header-user,
.btn-header {
	color: $white;
	font-size: 1.35rem;
	height: 61px;
	background-image: none;

	small {
		font-size: .65rem;
		display: block;
	}

	&.active {
		border: 0;
		color: $white;
		background-color: rgba($gray-200, .25);
	}

	&:hover {
		border-color: transparent;
		color: rgba($gray-200, .5);
	}
}

.btn-header {

	small {
		text-transform: uppercase;
	}
}

.btn-header-user {
	display: block;
	font-size: 2.5rem;
	text-align: right;
	padding-right: 55px;
	position: relative;
	z-index: 3;

	&.opened {
		color: $gray-800;
	}

	small {
		font-size: .85rem;
		display: block;
		padding-top: .15rem;

		&:nth-child(2) {
			font-weight: bold;
		}
	}

	.icon {
		position: absolute;
		right: 0;
		top: .65rem;
		line-height: 1;

		img {
			position: absolute;
			right: 0;
			top: 0;
			width: 2.5rem;
			height: 2.5rem;
			border: 2px solid $white;
			border-radius: 50%;
		}
	}
}

.btn-settings {
	padding: 1rem;

	i {
		display: block;
		font-size: 2rem;
		margin-bottom: .5rem;
	}
}

.divider {
	height: 2.5rem;
	width: 1px;
	background-color: $white;
	margin: 0 .75rem;
}

// Mobile menu

@import "collapse-menu";

// Loader

#global-spinner {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	align-items: center;
	justify-content: center;
	color: $white;

	.spinner-border {
		width: $spinner-size;
		height: $spinner-size;
	}
}
