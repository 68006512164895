.collapse-menu {
	position: fixed;
	overflow-y: auto;
	z-index: 10;
	padding: 80px 60px;
	color: $gray-200;
	right: 0;
	top: 2vh;
	bottom: 98vh;
	max-width: 480px;
	width: 100%;
	opacity: 0;
	transition: opacity .2s linear, top .2s ease-in-out, bottom .2s ease-in-out;
	display: none;
	box-shadow: -1px 0 10px 0 rgba($black, .4);
	background-color: $primary;

	@supports (backdrop-filter: blur(1rem)) {
		background-color: rgba($primary, .85);
		backdrop-filter: blur(1rem);
	}

	@include media-breakpoint-down("xs") {
		padding: 80px 22px;
		font-size: .9rem;
	}

	&.opened {
		opacity: 1;
		top: 0;
		bottom: 0;
	}

	.btn-close {
		height: 32px;
		border: none;
		position: absolute;
		top: 20px;
		right: 20px;
		color: $gray-200;
	}

	.customer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $gray-200;

		a {
			display: flex;
			align-items: center;
			place-items: center;
			color: $gray-200;
			padding: 16px 0;
			text-decoration: none;

			.icon {
				position: relative;
				display: inline-block;

				img {
					width: 36px;
					height: 36px;
					position: absolute;
					top: -2px;
					left: -2px;
					border-radius: 50%;
					border: 2px solid white;
				}
			}
		}

		a:not(.logout) {
			font-size: 1.1em;

			.fa {
				margin-right: .75rem;
				font-size: 32px;
			}
		}

		a.logout {
			font-size: 1em;

			.fa {
				position: relative;
				top: 2px;
				margin-left: .75rem;
				color: $gray-200;
			}
		}
	}

	.customer-picker {
		padding: 16px 0;
		border-bottom: 1px solid $gray-200;

		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;

			li {

				a {
					display: block;
					color: $gray-200;
					font-size: 1.2em;
					font-weight: bold;
					padding: 2px 0;
				}

				&.active {

					a {
						color: $yellow;
					}
				}
			}
		}
	}

	.main-menu {
		padding: 16px 0;

		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;

			.fa {
				display: inline-block;
				text-align: center;
				margin-right: 20px;
				width: 26px;
				font-size: 1.1em;
				color: $gray-200;
				position: relative;
				top: 2px;
			}

			li {

				a {
					display: flex;
					color: $gray-200;
					padding: 6px .5rem;
					margin: 4px -.5rem;
					text-decoration: none;

					.caret {
						top: 9px;
						transform: rotate(-90deg);
					}
				}

				&:focus,
				&:hover {

					a,
					.fa {
						text-decoration: none !important;
						color: rgba($gray-200, .5);
					}
				}

				&.active {

					a {
						background-color: rgba($gray-200, .25);
					}
				}
			}
		}
	}
}
